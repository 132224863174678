<template>
  <div :class="rootClasses">
    <div v-if="showHeaderSection" class="bitts-table-pro__cta-wrapper">
      <div class="bitts-table-pro__cta-left">
        <slot name="cta-left">
          <div
            :class="shrinkTitle ? null : 'flex-1'"
            class="text-neutral-900 text-lg"
          >
            {{ title }}
          </div>
        </slot>
      </div>
      <div class="bitts-table-pro__cta-right">
        <slot name="cta-right" />
      </div>
    </div>
    <div
      class="bitts-table-pro__ag-grid-container"
      :class="{
        'bitts-table-pro__empty': noRows,
        'fullstory-hide': hideFromFullstory,
      }"
    >
      <AgGridVue
        :cache-block-size="isServerSide ? paginationPageSize : undefined"
        :column-defs="columns"
        :default-col-def="defaultColumnDef"
        :row-data="rows"
        :enable-cell-text-selection="enableCellTextSelection"
        loading-cell-renderer="bittsRowLoader"
        :max-blocks-in-cache="0"
        no-rows-overlay-component="bittsEmptyState"
        :no-rows-overlay-component-params="{
          title: noRowsProps.title,
          description: noRowsProps.description,
          icon: noRowsProps.icon,
        }"
        :pagination="pagination"
        :suppress-pagination-panel="false"
        :pagination-page-size="paginationPageSize"
        :suppress-cell-focus="true"
        :suppress-row-hover-highlight="suppressRowHoverHighlight"
        :suppress-click-edit="true"
        :sorting-order="sortOrder"
        :row-selection="rowSelection"
        :row-height="rowHeight"
        :server-side-datasource="dataSource"
        :suppress-browser-resize-observer="true"
        :row-model-type="rowModelType"
        :row-multi-select-with-click="rowMultiSelectWithClick"
        :server-side-initial-row-count="
          isServerSide ? paginationPageSize : undefined
        "
        :suppress-row-click-selection="suppressRowClickSelection"
        :components="components"
        :context="context"
        class="ag-theme-balham"
        dom-layout="autoHeight"
        v-on="extraHandlers"
        @cell-clicked="onCellClicked"
        @row-clicked="onRowClicked"
        @first-data-rendered="onDataRendered"
        @grid-size-changed="onDataRendered"
        @grid-ready="onGridReady"
        @pagination-changed="onPaginationChanged"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Nullable } from '@crossbeam/types';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  CellClickedEvent,
  ColumnApi,
  FirstDataRenderedEvent,
  GridApi,
  GridReadyEvent,
  ModuleRegistry,
  RowClickedEvent,
} from '@ag-grid-community/core';
import { AgGridVue } from '@ag-grid-community/vue3';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import {
  Slots,
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  useSlots,
  watch,
} from 'vue';

import { AG_GRID_KEY } from './bitts-table-consts';
import BittsTableProEmptyState from './BittsTableProEmptyState.vue';
import BittsTableProRowLoader from './BittsTableProRowLoader.vue';
import {
  BittsTablePropsClientSide,
  BittsTablePropsServerSide,
  PassedContext,
} from './types';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-balham.css';

const {
  columns = [],
  compressColumns = false,
  dataSource = null,
  defaultColumnDef = {},
  enforceResponsiveSizing = false,
  enableCellTextSelection = false,
  hideFromFullstory = true,
  noRows = false,
  noRowsProps = {
    title: 'No results',
    description:
      'Try a different search term to find accounts or opportunities',
    icon: ['far', 'search'],
  },
  onRowSelected = null,
  pagination = true,
  paginationPageSize = 20,
  passedContext = {},
  rowHeight,
  rowModelType = 'clientSide',
  rowMultiSelectWithClick = null,
  rowSelection = null,
  rows = [],
  searchQuery = '',
  shrinkTitle = false,
  sortOrder = ['asc', 'desc'],
  stickyPagination = false,
  suppressRowClickSelection = null,
  suppressRowHoverHighlight = true,
  title = '',
  useSearch = false,
  zebra = false,
} = defineProps<BittsTablePropsClientSide | BittsTablePropsServerSide>();

const emit = defineEmits<{
  (e: 'pageClick', page: number): void;
  (e: 'grid-ready', params: GridReadyEvent): void;
  (e: 'columns-compressed'): void;
  (e: 'cell-clicked', data: CellClickedEvent): void;
  (e: 'row-clicked', data: RowClickedEvent['data']): void;
}>();

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ServerSideRowModelModule,
]);

LicenseManager.setLicenseKey(AG_GRID_KEY);

const isServerSide = computed(() => rowModelType === 'serverSide');

const page = ref<number>(1);
const lastPage = ref<Nullable<number>>(null);

function onPaginationChanged() {
  if (!gridApi.value?.paginationGetCurrentPage) return;
  lastPage.value = gridApi.value?.paginationGetTotalPages();
}

const gridApi = ref<Nullable<GridApi>>(null);
const gridColumnApi = ref<Nullable<ColumnApi>>(null);

const slots: Slots = useSlots();
const showHeaderSection = computed(() => title || !!slots['cta-left']);

type Component = ReturnType<typeof defineComponent>;
const context = ref<Nullable<PassedContext>>(null);
const components = ref<Nullable<Record<string, Component>>>(null);
onBeforeMount(() => {
  context.value = passedContext;
  components.value = {
    bittsEmptyState: BittsTableProEmptyState,
    bittsRowLoader: BittsTableProRowLoader,
  };
});

const rootClasses = computed(() => {
  return {
    'bitts-table-pro': true,
    'bitts-table-pro__sticky-pagination': stickyPagination,
    'bitts-table-pro__zebra': zebra,
    'with-header': showHeaderSection.value,
  };
});
const extraHandlers = computed(() => {
  if (!onRowSelected) return {};
  return { 'row-selected': onRowSelected };
});

// TODO: filter instead of find?
function buildResponsiveGrid(params: FirstDataRenderedEvent) {
  const flexColumn = params.columnApi
    .getColumns()
    ?.find((col) => col.getColDef().flex);

  if (flexColumn) {
    const gridPixelRange =
      document.querySelector('.bitts-table-pro')?.getBoundingClientRect()
        .width || 0;
    const pinnedLeftWidth =
      document
        .querySelector('.ag-pinned-left-cols-container')
        ?.getBoundingClientRect().width || 0;
    const centerWidth =
      document
        .querySelector('.ag-center-cols-container')
        ?.getBoundingClientRect().width || 0;
    const totalColumnWidths = pinnedLeftWidth + centerWidth;
    if (gridPixelRange >= totalColumnWidths) {
      params.api.sizeColumnsToFit();
    } else {
      if (params.type !== 'gridSizeChanged')
        params.columnApi.autoSizeAllColumns();
      params.columnApi.autoSizeColumn(flexColumn.getColId());
    }
  }
}
function onGridReady(params: GridReadyEvent) {
  gridApi.value = params.api;
  gridColumnApi.value = params.columnApi;

  lastPage.value = gridApi.value?.paginationGetTotalPages() || null;
  emit('grid-ready', params);
}

function onDataRendered(params: FirstDataRenderedEvent) {
  if (enforceResponsiveSizing) buildResponsiveGrid(params);
  else if (compressColumns) params.api.sizeColumnsToFit();
}

function onCellClicked(e: CellClickedEvent) {
  emit('cell-clicked', e);
}
function onRowClicked(e: RowClickedEvent) {
  emit('row-clicked', e.data);
}

watch(
  () => page.value,
  (newPageValue) => {
    if (!newPageValue) return;
    gridApi.value?.paginationGoToPage(newPageValue - 1);
  },
);
watch(
  () => searchQuery,
  (query) => {
    if (!useSearch) return;
    gridApi.value?.setQuickFilter(query);
  },
);

defineExpose({ gridApi });
</script>

<style lang="pcss" scoped>
.bitts-table-pro:deep() {
  .ag-cell {
    @apply text-neutral-text;
  }
  .ag-header,
  .ag-row {
    @apply bg-white border-neutral-border;
  }
  .ag-header-cell-text {
    @apply text-neutral-text-weak;
  }
  .ag-header-cell::after,
  .ag-header-group-cell::after {
    @apply hidden;
  }

  .ag-layout-auto-height .ag-center-cols-clipper,
  .ag-layout-auto-height .ag-center-cols-container {
    min-height: v-bind(rowHeight);
  }

  .ag-paging-button {
    @apply text-neutral-accent p-4 py-2 border-l border-solid border-neutral-border m-0;
    &:first-child {
      @apply border-l-0;
    }
    &.ag-disabled {
      @apply cursor-not-allowed;
    }
  }
  .ag-paging-description {
    @apply border-l border-solid border-neutral-border self-stretch flex items-center gap-2 m-0 px-8;
  }
  .ag-paging-page-summary-panel {
    @apply border border-solid border-neutral-border rounded-bts-sm px-4;
  }
  .ag-paging-panel {
    @apply flex flex-row-reverse bg-white justify-between items-center text-neutral-text font-bold;
  }

  .ag-pinned-left-header,
  .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(
      .ag-cell-range-single-cell
    ) {
    @apply border-r-0;
  }
  .ag-cell-value {
    @apply flex items-center text-neutral-text;
  }
  .ag-root-wrapper {
    @apply rounded-2xl border-neutral-border;
  }
  .ag-row-last {
    @apply border-b-0;
  }
  .ag-row-hover:not(.ag-full-width-row)::before,
  .ag-row-hover.ag-full-width-row.ag-row-group::before {
    @apply bg-neutral-background-weak;
  }
  .ag-row-hover {
    @apply cursor-pointer;
  }
}

.bitts-table-pro__zebra:deep() {
  .ag-row-odd,
  .ag-row-even {
    @apply border-none;
  }

  .ag-row-even {
    @apply bg-neutral-background-weak;
    &.ag-row-selected::before {
      @apply bg-neutral-background-weak rounded-16;
    }
  }
}
.bitts-table-pro__empty:deep() {
  @apply h-[500px];
  .ag-root {
    @apply h-[500px];
  }
  .ag-body-horizontal-scroll,
  .ag-paging-panel {
    @apply hidden;
  }
}

.bitts-table-pro__sticky-pagination:deep() {
  .ag-root,
  .ag-root-wrapper {
    display: unset;
  }
  .ag-header {
    @apply border-t border-solid border-neutral-border;
  }
  .ag-paging-panel {
    @apply sticky bottom-0 bg-white border-b border-solid border-neutral-border;
  }
}
</style>
